import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const thankYou = () => {
  return (
    <Layout>
      <Seo Sitetitle="Thnak you" />
      <section className="thank-you">
        <Container className="my-5">
          <Row className="justify-content-center align-items-center">
            <Col lg={6} className="my-4 my-lg-0">
              <h3>Thank you for contacting us!</h3>
              <p>We will get back to you as soon as possible!</p>
              <Link to="/">
                <button className="btn btn-yellow">Home</button>
              </Link>
            </Col>
            <Col lg={6}>
              <StaticImage
                src="../assets/images/thanks.svg"
                placeholder="tracedSVG"
                alt="thanks"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default thankYou
